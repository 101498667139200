<template>
  <custom-dialog ref="dialog" title="Deixar equipe" @submit="submit()">
    <span class="text-body-1f overtext--text">
      Você realmente deseja
      <span class="primary--text font-weight-bold">sair</span>?
      <span v-if="deleteMode">
        Esta ação resultará na
        <span class="primary--text font-weight-bold">exclusão</span> da equipe!
      </span>
    </span>

    <loader-hover v-if="loading" />
  </custom-dialog>
</template>

<script>
import { leaveTeam } from "@/services/teams.js";
import { displayAlert } from "@/utils";

export default {
  data() {
    return {
      loading: false,
    };
  },

  props: {
    team: {
      type: Object,
      default: () => {},
    },

    deleteMode: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    async submit() {
      try {
        this.loading = true;

        await leaveTeam(this.team.id).then(() => {
          this.displayAlert("Você saiu da equipe");
          this.$router.push({ path: "/leagues" });
          this.close();
        });
      } catch (err) {
        this.displayAlert(err, 1);
      } finally {
        this.loading = false;
      }
    },

    async handleDialog() {
      await this.$refs.dialog.openDialog();
    },

    close() {
      this.$refs.dialog.closeDialog();
    },

    displayAlert,
  },
};
</script>

<style></style>
