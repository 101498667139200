<template>
  <div class="fill-height">
    <v-hover v-slot:default="{ hover }">
      <v-card
        class="card px-8 py-2"
        height="100%"
        :class="{ zoom: manageAccess }"
        outlined
      >
        <v-img
          class="mb-4"
          :src="player.avatar || require('@/assets/utils/profile.png')"
          :aspect-ratio="4 / 4"
        >
          <div v-if="leader" class="d-flex fill-height justify-center pt-1">
            <v-avatar color="primary" size="26" rounded>
              <v-icon v-text="'mdi-crown'" color="#16181B" />
            </v-avatar>
          </div>
        </v-img>

        <v-btn
          v-if="hover && manageAccess && !leader"
          class="grey darken-2"
          absolute
          x-small
          right
          icon
          top
          @click="removePlayer()"
        >
          <v-icon color="black"> mdi-close </v-icon>
        </v-btn>

        <div class="text-center text-body-1 font-weight-bold">
          {{ player.nickname }}
        </div>
      </v-card>
    </v-hover>

    <loader-hover v-if="loading" />
  </div>
</template>

<script>
import { removePlayer } from "@/services/teams";
import { displayAlert } from "@/utils";

export default {
  data() {
    return {
      loading: false,
    };
  },

  props: {
    player: {
      type: Object,
      default: () => {},
    },

    team: {
      type: Object,
      default: () => {},
    },

    leader: {
      type: Boolean,
      default: false,
    },

    manageAccess: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    async removePlayer() {
      try {
        this.loading = true;

        const payload = {
          id: this.team.id,
          body: {
            players: [this.player.id],
          },
        };

        await removePlayer(payload).then(() => {
          this.displayAlert("Jogador removido com sucesso");
          this.$emit("success:remove");
        });
      } catch (err) {
        this.displayAlert("Falha ao remover jogador", 1);
      } finally {
        this.loading = false;
      }
    },

    displayAlert,
  },
};
</script>

<style lang="scss" scoped>
.card {
  border: thin solid #ff4655 !important;
  background-color: transparent;
}
</style>
