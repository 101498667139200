<template>
  <div>
    <v-form v-model="valid" ref="form" autocomplete="off">
      <div @keypress.enter.prevent="submit()" class="mb-4">
        <div class="label-input">Nome</div>
        <v-text-field
          v-model="form.name"
          class="rounded-lg"
          background-color="accent"
          placeholder="Nome da equipe"
          :rules="nameRules"
          solo
          flat
          @keydown.space.prevent
        />

        <div class="label-input">TAG</div>
        <v-text-field
          v-model="form.tag"
          v-mask="'AAA'"
          class="rounded-lg"
          background-color="accent"
          placeholder="Tag da equipe"
          :rules="tagRules"
          solo
          flat
          @keydown.space.prevent
        />
      </div>

      <div class="d-flex justify-end">
        <v-btn
          class="rounded-r-0 rounded-l-lg text-none mr-6"
          color="accent"
          width="96"
          large
          @click="close()"
        >
          Cancelar
        </v-btn>

        <v-btn
          class="rounded-l-0 rounded-r-lg text-none"
          color="primary"
          width="96"
          large
          @click="submit()"
        >
          Confirmar
        </v-btn>
      </div>
    </v-form>

    <loader-hover v-if="loading" />
  </div>
</template>

<script>
import { updateTeam } from "@/services/teams";
import { displayAlert } from "@/utils";
import { mask } from "vue-the-mask";

export default {
  data() {
    return {
      loading: false,
      valid: false,
      form: {
        name: "",
        tag: "",
      },
    };
  },

  props: {
    team: {
      type: Object,
      default: () => {},
    },
  },

  directives: {
    mask,
  },

  beforeMount() {
    this.startForm();
  },

  watch: {
    team() {
      this.startForm();
    },
  },

  computed: {
    nameRules() {
      return [
        (v) => !!v || "Campo Obrigatório",
        (v) => v.length >= 3 || "Nome inválida",
      ];
    },

    tagRules() {
      return [
        (v) => !!v || "Campo Obrigatório",
        (v) => v.length >= 3 || "Sigla inválida",
      ];
    },
  },

  methods: {
    async submit() {
      if (!this.$refs.form.validate()) return;

      try {
        this.loading = true;

        const payload = {
          id: this.team.id,
          body: {
            name: this.form.name,
            tag: this.form.tag,
          },
        };

        await updateTeam(payload).then(() => {
          this.$emit("success");
          this.close();
        });
      } catch (err) {
        this.displayAlert(err.data.message, 1);
      } finally {
        this.loading = false;
      }
    },

    startForm() {
      this.form.name = this.team.name;
      this.form.tag = this.team.tag;
    },

    close() {
      this.$emit("close");
    },

    displayAlert,
  },
};
</script>

<style></style>
