<template>
  <loader-view v-if="loading" />

  <div v-else>
    <page-header
      :background="selected.background || require('@/assets/leagues/img.png')"
    />

    <v-container>
      <v-row
        :class="`row-content-${
          $vuetify.breakpoint.mdAndUp ? 'desktop' : 'mobile'
        }`"
        justify="center"
      >
        <v-col cols="12" sm="8" md="6" lg="5" xl="4">
          <v-card color="surface" class="pb-6">
            <!-- logo -->
            <v-row class="mb-4" justify="center">
              <v-col cols="6">
                <div
                  :class="{ zoom: manageMode, pointer: manageMode }"
                  @click="changeAvatar()"
                >
                  <v-img
                    class="mt-n16"
                    :src="
                      selected.logo ||
                      require('@/assets/leagues/default-league-logo.svg')
                    "
                    :aspect-ratio="4 / 4"
                    contain
                  />

                  <div
                    v-if="manageMode"
                    class="d-flex align-center justify-center"
                  >
                    <span
                      class="text-body-1 primary--text text-decoration-underline"
                    >
                      Alterar logo
                    </span>
                  </div>
                </div>
              </v-col>
            </v-row>

            <!-- info mode -->
            <div v-if="!manageMode" class="d-flex flex-column px-4 px-md-8">
              <div class="d-flex justify-center align-center mb-8">
                <v-sheet
                  color="primary"
                  class="rounded-lg mr-4"
                  height="54"
                  width="4"
                />

                <div class="d-flex flex-column">
                  <div class="d-flex align-center">
                    <span class="text-h6 overtext--text font-weight-bold">
                      {{ selected.name }}
                    </span>

                    <v-divider class="mx-3 divider" />

                    <span class="text-body-2 subtext--text">
                      Nome do equipe
                    </span>
                  </div>

                  <div class="d-flex align-center">
                    <span class="text-h6 overtext--text font-weight-bold">
                      {{ selected.tag }}
                    </span>

                    <v-divider class="mx-3 divider" />

                    <span class="text-body-2 subtext--text"> Tag </span>
                  </div>
                </div>
              </div>

              <!-- actions -->
              <v-row no-gutters>
                <v-col
                  v-if="isManager && isMember"
                  cols="12"
                  md="6"
                  class="pr-md-2 mb-4 mb-md-0"
                >
                  <v-btn
                    class="rounded-r-0 rounded-l-lg text-none"
                    color="primary"
                    block
                    @click="manageMode = true"
                  >
                    <v-icon left>mdi-cog</v-icon>
                    Gerenciar equipe
                  </v-btn>
                </v-col>

                <v-col
                  cols="12"
                  :md="isManager && isMember ? '6' : '12'"
                  :class="isManager && isMember ? 'pl-md-2' : ''"
                >
                  <v-btn
                    v-if="isMember"
                    class="rounded-l-0 rounded-r-lg text-none"
                    color="accent lighten-1"
                    block
                    @click="leaveTeam()"
                  >
                    <v-icon left>mdi-minus</v-icon>
                    Sair da equipe
                  </v-btn>

                  <v-btn
                    v-else
                    class="rounded-l-0 rounded-r-lg text-none"
                    color="primary"
                    block
                    @click="joinTeam()"
                  >
                    <v-icon left>mdi-arrow-right</v-icon>
                    Entrar na equipe
                  </v-btn>
                </v-col>
              </v-row>
            </div>

            <!-- manage mode -->
            <div v-else class="d-flex flex-column px-4 px-md-8">
              <EditTeam
                :team="selected"
                @close="manageMode = false"
                @success="getData()"
              />
            </div>
          </v-card>
        </v-col>
      </v-row>

      <v-container class="py-16">
        <!-- invite card -->
        <v-card
          v-show="manageMode && selected.league.x > 1"
          class="mb-12"
          color="surface"
          flat
        >
          <div class="d-flex flex-column pa-6">
            <div class="d-flex align-center mb-3">
              <v-icon v-text="'$hammer2'" left />

              <span class="text-h5 font-weight-bold">
                <span class="saira">Convite</span>
              </span>
            </div>

            <span class="text-body-1 mb-6">
              Copie a URL abaixo e envie para outros jogadores, para que possam
              ser convidados a entrarem na sua equipe.
            </span>

            <v-text-field
              v-model="inviteUrl"
              background-color="accent"
              readonly
              solo
              flat
              @focus="copy()"
            >
              <template v-slot:append>
                <v-btn
                  class="rounded-l-0 rounded-r-lg text-none"
                  color="primary"
                  outlined
                  @click="copy()"
                >
                  <v-icon left> mdi-content-copy </v-icon>
                  Copiar
                </v-btn>
              </template>
            </v-text-field>
          </div>
        </v-card>

        <v-row class="mb-12">
          <!-- players -->
          <v-col cols="12" md="7" lg="8" xl="9">
            <!-- title -->
            <div class="d-flex align-center mb-6">
              <v-icon v-text="'$hammer2'" left />

              <span class="text-h5 font-weight-bold mr-4">
                <span class="saira">Jogadores</span>
              </span>

              <span class="subtext--text">
                {{ selected.team_players.length }} de {{ selected.league.x }}
              </span>

              <v-spacer />
            </div>

            <!-- content -->
            <v-row v-if="selected.team_players.length > 0">
              <v-col
                v-for="(item, i) in selected.team_players"
                cols="6"
                sm="4"
                xl="2"
                :key="i"
              >
                <PlayerCard
                  :player="item.user"
                  :team="selected"
                  :manageAccess="isManager && manageMode"
                  :leader="selected.userId === item.user.id"
                  @success:remove="getData()"
                />
              </v-col>

              <v-col
                v-if="
                  selected.team_players.length < selected.league.x && isMember
                "
                cols="6"
                sm="4"
                xl="2"
              >
                <v-card class="card-invite zoom" height="100%" @click="copy()">
                  <v-responsive :aspect-ratio="4 / 4">
                    <div class="d-flex fill-height align-center justify-center">
                      <v-icon color="accent" x-large>
                        mdi-plus-circle-outline
                      </v-icon>
                    </div>
                  </v-responsive>
                </v-card>
              </v-col>
            </v-row>

            <!-- empty -->
            <div v-else class="d-flex flex-column align-center pb-12">
              <span class="text-body-1 text-center subtext--text">
                Nenhum resultado foi encontrado
              </span>
            </div>
          </v-col>

          <!-- leagues -->
          <v-col cols="12" md="5" lg="4" xl="3">
            <!-- title -->
            <div class="d-flex align-center mb-6">
              <v-icon v-text="'$hammer2'" left />

              <span class="text-h5 font-weight-bold">
                <span class="saira">Inscrito na liga</span>
              </span>
            </div>

            <!-- content -->
            <LeagueCard class="mt-n5" :league="selected.league" />
          </v-col>
        </v-row>

        <div
          v-if="
            selected.team_players.length <= 1 &&
            selected.team_players.length < selected.league.x &&
            isManager
          "
          class="d-flex justify-center my-8"
        >
          <v-btn
            class="rounded-l-0 rounded-r-lg text-none"
            color="grey lighten-1"
            outlined
            @click="leaveTeam()"
          >
            <v-icon left>mdi-delete</v-icon>
            Excluir equipe
          </v-btn>
        </div>
      </v-container>
    </v-container>

    <ChangeAvatar ref="changeAvatar" :team="selected" @success="getData()" />

    <LeaveTeam
      ref="leaveTeam"
      :team="selected"
      :deleteMode="selected.team_players.length <= 1"
    />
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { getTeam } from "@/services/teams";
import { displayAlert } from "@/utils";
import EditTeam from "@/components/teams/EditTeam.vue";
import ChangeAvatar from "@/components/teams/ChangeAvatar.vue";
import PlayerCard from "@/components/teams/PlayerCard.vue";
import LeaveTeam from "@/components/teams/LeaveTeam.vue";
import LeagueCard from "@/components/leagues/LeagueCard.vue";

export default {
  data() {
    return {
      loading: true,
      manageMode: false,
    };
  },

  components: {
    EditTeam,
    ChangeAvatar,
    PlayerCard,
    LeagueCard,
    LeaveTeam,
  },

  beforeMount() {
    this.getData();
  },

  computed: {
    ...mapState(["localUser", "selected", "appURL"]),

    isManager() {
      return this.selected.userId === this.localUser.id;
    },

    isMember() {
      if (!this.selected.team_players || !this.selected.team_players.length)
        return false;

      return this.selected.team_players.find(
        (e) => e.user.id === this.localUser.id
      );
    },

    inviteUrl() {
      return `${this.appURL}/invite/${this.selected.code}`;
    },
  },

  methods: {
    ...mapActions(["setSelected"]),

    async getData() {
      try {
        await getTeam(this.$route.params.id).then((res) => {
          this.setSelected(res);
          this.loading = false;
        });
      } catch (err) {
        this.displayAlert("Equipe não encontrada", 1);
        this.$router.push({ path: "/account/teams" });
      }
    },

    changeAvatar() {
      if (!this.manageMode) return;

      this.$refs.changeAvatar.handleDialog();
    },

    joinTeam() {
      this.$router.push({
        path: `/teams/new?leagueId=${this.selected.league.id}&invite=true`,
      });
    },

    leaveTeam() {
      this.$refs.leaveTeam.handleDialog();
    },

    copy() {
      const TempText = document.createElement("input");
      TempText.value = this.inviteUrl;
      document.body.appendChild(TempText);
      TempText.select();

      document.execCommand("copy");
      document.body.removeChild(TempText);

      this.displayAlert("Convite copiado para área de transferência", 2);
    },

    displayAlert,
  },
};
</script>

<style lang="scss" scoped>
.row-content {
  &-mobile {
    margin-top: -64px;
  }

  &-desktop {
    margin-top: -192px;
  }
}

.divider {
  min-width: 35px;
  max-width: 35px;
}

.card-invite {
  border: thin solid #2e3036 !important;
  background-color: transparent;
}
</style>
