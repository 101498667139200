<template>
  <custom-dialog ref="dialog" title="Alterar logo da equipe" @submit="submit()">
    <v-item-group v-model="form.avatar" mandatory>
      <v-row>
        <v-col v-for="(item, i) in items" :key="i" cols="6" md="3">
          <v-item v-slot="{ active, toggle }">
            <v-card
              :color="active ? 'primary' : 'accent'"
              class="d-flex align-center pa-1"
              dark
              @click="toggle"
            >
              <v-img :src="item" :aspect-ratio="4 / 4" contain />
            </v-card>
          </v-item>
        </v-col>
      </v-row>
    </v-item-group>

    <loader-hover v-if="loading" />
  </custom-dialog>
</template>

<script>
import { updateTeam } from "@/services/teams";
import { displayAlert } from "@/utils";

export default {
  data() {
    return {
      loading: false,
      items: [
        "https://lolstatic-a.akamaihd.net/frontpage/apps/prod/clash-2018/pt_BR/a46e742ae82f9d4f9db8e34ba57873e513e727b7/assets/static/svg/team1.svg",
        "https://lolstatic-a.akamaihd.net/frontpage/apps/prod/clash-2018/pt_BR/a46e742ae82f9d4f9db8e34ba57873e513e727b7/assets/static/svg/team2.svg",
        "https://lolstatic-a.akamaihd.net/frontpage/apps/prod/clash-2018/pt_BR/a46e742ae82f9d4f9db8e34ba57873e513e727b7/assets/static/svg/team3.svg",
        "https://lolstatic-a.akamaihd.net/frontpage/apps/prod/clash-2018/pt_BR/a46e742ae82f9d4f9db8e34ba57873e513e727b7/assets/static/svg/team4.svg",
        "https://lolstatic-a.akamaihd.net/frontpage/apps/prod/clash-2018/pt_BR/a46e742ae82f9d4f9db8e34ba57873e513e727b7/assets/static/svg/team5.svg",
        "https://lolstatic-a.akamaihd.net/frontpage/apps/prod/clash-2018/pt_BR/a46e742ae82f9d4f9db8e34ba57873e513e727b7/assets/static/svg/team6.svg",
        "https://lolstatic-a.akamaihd.net/frontpage/apps/prod/clash-2018/pt_BR/a46e742ae82f9d4f9db8e34ba57873e513e727b7/assets/static/svg/team7.svg",
        "https://lolstatic-a.akamaihd.net/frontpage/apps/prod/clash-2018/pt_BR/a46e742ae82f9d4f9db8e34ba57873e513e727b7/assets/static/svg/team8.svg",
        "https://lolstatic-a.akamaihd.net/frontpage/apps/prod/clash-2018/pt_BR/a46e742ae82f9d4f9db8e34ba57873e513e727b7/assets/static/svg/team9.svg",
        "https://lolstatic-a.akamaihd.net/frontpage/apps/prod/clash-2018/pt_BR/a46e742ae82f9d4f9db8e34ba57873e513e727b7/assets/static/svg/team10.svg",
        "https://lolstatic-a.akamaihd.net/frontpage/apps/prod/clash-2018/pt_BR/a46e742ae82f9d4f9db8e34ba57873e513e727b7/assets/static/svg/team11.svg",
        "https://lolstatic-a.akamaihd.net/frontpage/apps/prod/clash-2018/pt_BR/a46e742ae82f9d4f9db8e34ba57873e513e727b7/assets/static/svg/team12.svg",
        "https://lolstatic-a.akamaihd.net/frontpage/apps/prod/clash-2018/pt_BR/a46e742ae82f9d4f9db8e34ba57873e513e727b7/assets/static/svg/team13.svg",
        "https://lolstatic-a.akamaihd.net/frontpage/apps/prod/clash-2018/pt_BR/a46e742ae82f9d4f9db8e34ba57873e513e727b7/assets/static/svg/team14.svg",
        "https://lolstatic-a.akamaihd.net/frontpage/apps/prod/clash-2018/pt_BR/a46e742ae82f9d4f9db8e34ba57873e513e727b7/assets/static/svg/team15.svg",
        "https://lolstatic-a.akamaihd.net/frontpage/apps/prod/clash-2018/pt_BR/a46e742ae82f9d4f9db8e34ba57873e513e727b7/assets/static/svg/team16.svg",
      ],
      form: {
        avatar: 0,
      },
    };
  },

  props: {
    team: {
      type: Object,
      default: () => {},
    },
  },

  beforeMount() {
    this.startForm();
  },

  methods: {
    async submit() {
      if (this.form.avatar < 0) return;

      try {
        this.loading = true;

        const payload = {
          id: this.team.id,
          body: {
            logo: this.items[this.form.avatar],
          },
        };

        await updateTeam(payload).then(() => {
          this.$emit("success");
          this.close();
        });
      } catch (err) {
        this.displayAlert(err.data.message, 1);
      } finally {
        this.loading = false;
      }
    },

    startForm() {
      const index = this.items.indexOf(this.team.logo);
      this.form.avatar = index;
    },

    async handleDialog() {
      await this.$refs.dialog.openDialog();
    },

    close() {
      this.$refs.dialog.closeDialog();
    },

    displayAlert,
  },
};
</script>

<style></style>
